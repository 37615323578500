/* -------------------------------------------------------------------------- *
 * Background Image loop *
 * -------------------------------------------------------------------------- *
 * Use a data-background attribute to store the asset from Craft CMS
 * data-background="{{ featuredBackgroundImage.getUrl('featuredBrandVideoBackgroundImage') }}"
 * Use data-dimmer="true" to add a graphic that will add a dimming overlay to the background image
 */

const settings = {
    selector: '.js-backgroundImageLoop',
};

/* ------------- *
 * Module Export *
 * ------------- */

const BackgroundImages = {
    selector: settings.selector,

    init(selector = settings.selector) {
        const backgroundImages = document.querySelectorAll(selector);
        const backgroundImage_arr = Array.from(backgroundImages);

        // Map the new Class object to the element
        backgroundImage_arr.forEach((el) => {
            if (el.dataset.dimmer === 'true') {
                el.style.backgroundImage = `url("/images/dimmer.png"), url(${el.dataset.background})`;
            } else {
                el.style.backgroundImage = `url(${el.dataset.background})`;
            }
        });
    },
};

export default BackgroundImages;
