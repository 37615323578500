/* -------------------------------------------------------------------------- *
 * Toggle Buttons *
 * -------------------------------------------------------------------------- *
 * Toggles the display of a target element when clicking on a trigger element.
 * Settings and initial state are controlled by ARIA attributes and the state
 * class. Styling for both the trigger and target element should be adjusted
 * using the state class that is added to both elements on click. The
 * data-group attribute is optional and can be used to force only one target
 * and trigger element to be active at one time by setting a common value. If
 * the element is not a button (e.g. an anchor tag), the role attribute should
 * be set to button. If the element is not normally focusable (e.g. a span)
 * then the tabindex attribute should be set to 0.
 *
 * -------------------------- *
 * Trigger Element Attributes *
 * -------------------------- *
 * class="js-toggle"
 * aria-controls="js-targetId"
 * aria-expanded="false"
 * data-group="navDropdown"
 * role="button"
 * tabindex="0"
 *
 * ------------------------- *
 * Target Element Attributes *
 * ------------------------- *
 * id="js-targetId"
 */

const settings = {
    selector: '.js-toggle',
    state_class: 'is-active',
};

const groups_arr = [];

class ToggleButton {
    constructor(el) {
        // Use attribute values for initial state
        const aria_controls = el.getAttribute('aria-controls');
        const aria_expanded = el.getAttribute('aria-expanded');
        const button_group = el.getAttribute('data-group');

        // Object properties
        this.trigger_el = el;
        this.target_el = document.getElementById(aria_controls);
        this.toggled = aria_expanded === 'true';

        // Add object to group array
        if (button_group !== undefined) {
            this.group = button_group;
            groups_arr[this.group] = groups_arr[this.group] || [];
            groups_arr[this.group].push(this);
        }

        // Show/hide content on click
        this.trigger_el.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleDisplay();
        });
    }

    toggleDisplay() {
        // Check for button group
        if (this.group) {
            // Close open buttons in the same group
            groups_arr[this.group].forEach((button) => {
                if (button !== this && button.toggled === true) {
                    button.toggleDisplay();
                }
            });
        }

        // Update state
        this.trigger_el.classList.toggle(settings.state_class);
        this.trigger_el.setAttribute('aria-expanded', !this.toggled);

        if (this.target_el) {
            this.target_el.classList.toggle(settings.state_class);
        }
        this.toggled = !this.toggled;
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const Toggle = {
    selector: settings.selector,

    buttons: new Map(),

    init(selector = settings.selector) {
        const buttons = document.querySelectorAll(selector);
        const button_arr = Array.from(buttons);

        // Map the new Class object to the element
        button_arr.forEach((el) => {
            Toggle.buttons.set(el, new ToggleButton(el));
        });
    },
};

export default Toggle;
