/* -------------------------------------------------------------------------- *
 * Sticky Nav Styling *
 * -------------------------------------------------------------------------- *
 * Adds a class to the page header element once the page has scrolled past the
 * target element. The target element is typically found at the top of the
 * page, but the minimum amount of scrolling required before triggering the
 * script can be adjusted using the rootMargin setting.
 */

const settings = {
    header: '#js-header',
    main: '#main',
    target: '#js-stickyTarget',
    state_class: 'is-scrolled',
    observer_options: {
        root: null,
        rootMargin: '147px',
        threshold: 0,
    },
};

let header_el;
let main_el;

const observer = new IntersectionObserver((entries) => {
    // Toggle class based on scroll position
    if (entries[0].isIntersecting === false) {
        header_el.classList.add(settings.state_class);
        main_el.classList.add(settings.state_class);
    } else {
        header_el.classList.remove(settings.state_class);
        main_el.classList.remove(settings.state_class);
    }
}, settings.observer_options);

/* ------------- *
 * Module Export *
 * ------------- */

const StickyNav = {
    init() {
        const target_el = document.querySelector(settings.target);
        header_el = document.querySelector(settings.header);
        main_el = document.querySelector(settings.main);

        // Begin observing scroll
        observer.observe(target_el);
    },
};

export default StickyNav;
