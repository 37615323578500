/* -------------------------------------------------------------------------- *
 * Browser Cookies *
 * -------------------------------------------------------------------------- *
 * Methods for getting, setting, and deleting browser cookies.
 */

// Convert cookies string 'name=value; ...' to object { name: value, ... }.
const cookies = document.cookie
    .split('; ')
    .reduce((cookies_obj, cookie_str) => {
        const [name, value] = cookie_str.split('=');
        cookies_obj[name] = value;

        return cookies_obj;
    }, {});

/* ------------- *
 * Module Export *
 * ------------- */

const Cookies = {

    /* Gets the value of a specific cookie.
     * @param {string} name - The unique name of the cookie.
     */

    get(name) {
        return cookies[name];
    },

    /* Set a cookie's value and expiration date.
     * @param {string} name - The unique name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {number} days - The number of days before the cookie expires.
     */

    set(name, value = '', days = 365) {
        // Set date for expiration and convert to UTC string.
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const date_str = date.toUTCString();

        // Set the cookie.
        document.cookie = `${name}=${value}; expires=${date_str}; path=/`;
    },

    /* Removes the cookie's value and expires it.
     * @param {string} name - The unique name of the cookie.
     */

    delete(name) {
        Cookies.set(name, '', -1);
    },
};

export default Cookies;
