/* -------------------------------------------------------------------------- *
 * Carousel *
 * -------------------------------------------------------------------------- *
 * Accessible carousel using the slick carousel plugin.
 * https://kenwheeler.github.io/slick/
 */
/* global $ */

const settings = {
    selector: '.js-carousel',
    slick: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 15000,
        arrows: false,
        dots: true,
        appendDots: '.homepageCarousel__dots',
        speed: 500,
        fade: true,
        cssEase: 'linear',
    },
};

/* ------------- *
 * Module Export *
 * ------------- */

const Carousel = {
    selector: settings.selector,

    init(selector = settings.selector,
        optionsMain = settings.slick) {
        const carouselsMain = document.querySelectorAll(selector);
        const carouselsMain_arr = Array.from(carouselsMain);
        carouselsMain_arr.forEach((el) => {
            $(el).slick(optionsMain);
        });
    },
};
export default Carousel;
