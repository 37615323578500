/* -------------------------------------------------------------------------- *
 * Lazy Loading for Media and Iframes *
 * -------------------------------------------------------------------------- *
 * Lazy loading for media including <img>, <picture>, <video>, and <iframe>, as
 * well as background image lazy loading for other elements. To lazy load
 * media, add the selector class and the required data attributes. Supported
 * data-attributes include data-src and data-srcset when combined with either
 * data-sizes (<img>) or data-media (<picture>). For <picture> and <video>,
 * include the selector class on the element, child elements. The child <img>
 * and <source> elements will be pulled in automatically.
 */

const settings = {
    selector: '.js-lazy',
    state_class: 'is-loaded',
    observer_options: {
        root: null,
        rootMargin: `0px 0px ${window.innerHeight}px 0px`,
        threshold: 0,
    },
};

// Method lookup function for loading media
function loadMedia(el) {
    const load = {
        // Image elements
        img() {
            function setAttributes(img) {
                if (img.dataset.sizes && img.dataset.srcset) {
                    // Update sizes
                    img.sizes = img.dataset.sizes;
                    img.removeAttribute('data-sizes');

                    // Update source set
                    img.srcset = img.dataset.srcset;
                    img.removeAttribute('data-srcset');
                }

                if (img.dataset.src) {
                    // Update source
                    img.src = img.dataset.src;
                    img.removeAttribute('data-src');
                }

                // Add state class to image
                img.classList.add(settings.state_class);
            }

            // Preload image
            const img = el.cloneNode(true);
            setAttributes(img);

            // Load image on page
            img.addEventListener('load', () => {
                setAttributes(el);
            });
        },

        // Picture elements
        picture() {
            function setAttributes(picture) {
                const img = picture.querySelector('img');

                // Update source
                img.src = img.dataset.src;
                img.removeAttribute('data-src');

                const sources = picture.querySelectorAll('source');
                const source_arr = Array.from(sources);

                source_arr.forEach((source) => {
                    if (source.dataset.srcset && source.dataset.media) {
                        // Update source set
                        source.srcset = source.dataset.srcset;
                        source.removeAttribute('data-srcset');

                        // Update media
                        source.media = source.dataset.media;
                        source.removeAttribute('data-media');
                    }
                });

                // Add state class
                img.classList.add(settings.state_class);
            }

            // Preload picture
            const picture = el.cloneNode(true);
            const img = picture.querySelector('img');
            setAttributes(picture);

            // Load picture on page
            img.addEventListener('load', () => {
                setAttributes(el);
            });
        },

        // Video elements
        video() {
            const sources = el.querySelectorAll('source');
            const source_arr = Array.from(sources);

            source_arr.forEach((source) => {
                if (source.dataset.src) {
                    // Update source
                    source.src = source.dataset.src;
                    el.removeAttribute('data-src');
                }
            });

            // Load video on page
            el.load();

            // Add state class
            el.classList.add(settings.state_class);
        },

        // Iframe elements
        iframe() {
            if (el.dataset.src) {
                // Update source
                el.src = el.dataset.src;
                el.removeAttribute('data-src');
            }
        },

        // Other elements
        background() {
            if (el.dataset.src) {
                // Preload background
                const img = document.createElement('img');
                img.src = el.dataset.src;

                // Load background on page
                img.addEventListener('load', () => {
                    // Update inline style
                    el.style.backgroundImage = `url("${el.dataset.src}")`;
                    el.removeAttribute('data-src');
                });
            }
        },
    };

    // Check if method for element tag exists
    const tag_name = el.tagName.toLowerCase();

    if (load[tag_name]) {
        load[tag_name]();
    } else {
        // Fall back to background method
        load.background();
    }
}

let observed_els = 0;

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            loadMedia(entry.target);

            // Remove from observation once loaded
            observer.unobserve(entry.target);
            observed_els--;

            // Disconnect observer once all media is loaded
            if (observed_els === 0) {
                observer.disconnect();
            }
        }
    });
}, settings.observer_options);

/* ------------- *
 * Module Export *
 * ------------- */

const LazyLoad = {
    selector: settings.selector,

    /* Initializes the lazy load observer
     * @param {string} selector - The selector for the element(s) to load.
     */

    init(selector = settings.selector) {
        const elements = document.querySelectorAll(selector);
        const el_arr = Array.from(elements);

        el_arr.forEach((el) => {
            observer.observe(el);
            observed_els++;
        });
    },
};

export default LazyLoad;
