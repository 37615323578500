/* -------------------------------------------------------------------------- *
 * Notification Popup *
 * -------------------------------------------------------------------------- *
 * Controls the display of the site wide notification banner. Requires a button
 * element with the date field value as a value attribute.
 */

import Cookies from './cookies';

const settings = {
    selector: '#js-notification',
    state_class: 'is-active',
};

let notification_el;

function dismissNotification(e) {
    e.preventDefault();

    // Set a cookie with the value of the date updated
    const date = this.value;
    Cookies.set('notification', date);

    // Hide the notification
    notification_el.classList.remove(settings.state_class);
}

/* ------------- *
 * Module Export *
 * ------------- */

const Notification = {
    selector: settings.selector,

    /* Initializes the cookie consent popup
     * @param {string} selector - The selector for the parent element.
     */

    init(selector = settings.selector) {
        notification_el = document.querySelector(selector);

        const button_el = notification_el.querySelector('button');
        button_el.addEventListener('click', dismissNotification);
    },
};

export default Notification;
