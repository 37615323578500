/* -------------------------------------------------------------------------- *
 * Cookie Consent Popup *
 * -------------------------------------------------------------------------- *
 * Controls the storage of the users cookie preferences using a dialog element.
 * Requires a hidden input field whose value is the GTM ID, as well as two
 * buttons for submitting the form: one a value of true for accepting cookies
 * and another with a value of false for declining them.
 */

import Cookies from './cookies';

const settings = {
    selector: '#js-consent',
    state_class: 'is-active',
};

let dialog;

function hideConsent(e) {
    e.preventDefault();

    // Set the cookie
    const consent = this.value;
    Cookies.set('consent', consent);

    // Hide the dialog
    dialog.removeAttribute('open');

    // Add the GTM script if consent was given
    if (consent === 'true') {
        const gtm_id = dialog.querySelector('input').value;

        if (gtm_id !== '') {
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                    new Date().getTime(),
                    event: 'gtm.js',
                }); const f = d.getElementsByTagName(s)[0];
                // eslint-disable-next-line eqeqeq
                const j = d.createElement(s); const dl = l != 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src =                `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
            }(window, document, 'script', 'dataLayer', gtm_id));

            window.dataLayer = window.dataLayer || [];

            // eslint-disable-next-line no-inner-declarations,no-undef,prefer-rest-params
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'UA-58621604-1');
        }
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const Consent = {
    selector: settings.selector,

    /* Initializes the cookie consent popup
     * @param {string} selector - The selector for the form element.
     */

    init(selector = settings.selector) {
        dialog = document.querySelector(selector);

        const buttons = dialog.querySelectorAll('button');
        const button_arr = Array.from(buttons);

        button_arr.forEach((el) => {
            el.addEventListener('click', hideConsent);
        });
    },
};

export default Consent;
