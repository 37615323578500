/* -------------------------------------------------------------------------- *
 * Custom Suvery Module *
 * -------------------------------------------------------------------------- *
 * Accessible lightbox gallery using the Topbii lightbox plugin.
 * https://kenwheeler.github.io/slick/
 */

/* ------------- *
 * Module Export *
 * ------------- */

const useSeconds = false;
let debugMode = false;
let currentURL;
let settings;
let currentIP;
let adminStatus;
let ruleFound = false;

function logText(txt) {
    if (debugMode) {
        console.log(txt);
    }
}
function openDialog(contentDiv) {
    // create overlay
    const overlay = document.createElement('div');

    // create dialog container
    const dialogContainer = document.createElement('div');
    dialogContainer.classList.add('dialog-container');

    // add close button
    const closeButton = document.createElement('div');
    closeButton.classList.add('dialog-close-button');
    closeButton.classList.add('close-button');
    closeButton.addEventListener('click', () => {
        // eslint-disable-next-line no-use-before-define
        document.body.removeChild(overlay);
        document.body.removeChild(dialogContainer);
    });
    dialogContainer.appendChild(closeButton);

    // add content div
    contentDiv.style.display = 'block';
    contentDiv.classList.add('dialog-content');
    dialogContainer.appendChild(contentDiv);
    overlay.classList.add('dialog-overlay');
    overlay.addEventListener('click', () => {
        document.body.removeChild(overlay);
        document.body.removeChild(dialogContainer);
    });

    // add elements to DOM
    document.body.appendChild(overlay);
    document.body.appendChild(dialogContainer);
}

// Function to get cookie value
function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const parts = cookies[i].split('=');
        if (decodeURIComponent(parts[0]) === name) {
            const arr = JSON.parse(decodeURIComponent(parts[1]));
            if (arr.length > 0) {
                return JSON.parse(decodeURIComponent(parts[1]));
            }
            return [];
        }
    }
    return [];
}

// Function to set cookie value
function setCookie(name, value, options) {
    options = options || {};
    let expires = options.expires;
    if (typeof expires === 'number' && expires) {
        const d = new Date();
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        // eslint-disable-next-line no-multi-assign
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }
    value = encodeURIComponent(JSON.stringify(value));
    let updatedCookie = `${name}=${value}`;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const propName in options) {
        updatedCookie += `; ${propName}`;
        const propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += `=${propValue}`;
        }
    }
    document.cookie = updatedCookie;
    settings = getCookie('settings') || [];
}

// Function to set object in cookie
function setSetting(url, shown, expirydate, ruleids) {
    const currentDate = new Date();
    const updatedSettings = [];
    for (let i = 0; i < settings.length; i++) {
        const setting = settings[i];
        if (setting.ruleid === ruleids) {
            setting.url = url;
            setting.shown = shown;
            setting.expirydate = expirydate;
            updatedSettings.push(setting);
        } else if (!setting.expirydate || new Date(setting.expirydate) > currentDate) {
            updatedSettings.push(setting);
        }
    }
    let settingToUpdate = updatedSettings.find((s) => s.ruleid === ruleids);
    if (!settingToUpdate) {
        settingToUpdate = {
            url, shown, expirydate, ruleid: ruleids,
        };
        updatedSettings.push(settingToUpdate);
    }
    setCookie('settings', updatedSettings, { expires: 365 });
}

// Function to remove expired settings
function removeExpiredSettings() {
    // Remove expired settings
    const currentDate = new Date();
    const updatedSettings = [];
    for (let i = 0; i < settings.length; i++) {
        const setting = settings[i];
        if (!setting.expirydate || new Date(setting.expirydate) > currentDate) {
            updatedSettings.push(setting);
        }
    }
    if (updatedSettings.length < settings.length) {
        setCookie('settings', updatedSettings, { expires: 365 });
    }
}

function  removeSettingsNotInIds(arr) {
    const obj = settings;
    Object.keys(obj).forEach((key) => {
        if (!arr.includes(obj[key].id)) {
            delete obj[key];
        }
    });
}

function getPathFromUrl(url) {
    const path = url.split('#')[0].split('?')[0];
    let extractedPath = new URL(path).pathname.toLowerCase().trim();
    if (extractedPath === '' || extractedPath === '/') {
        extractedPath = '/';
    } else if (!extractedPath.endsWith('/')) {
        extractedPath += '/';
    }
    return extractedPath;
}

function loadJsonFile(filename, callback) {
    const xhr = new XMLHttpRequest();
    xhr.overrideMimeType('application/json');
    xhr.open('GET', filename, true);
    xhr.setRequestHeader('cache-control', 'no-cache');
    xhr.onreadystatechange = function ready() {
        if (xhr.readyState === 4 && xhr.status === 200) {
            callback(JSON.parse(xhr.responseText));
        }
    };
    xhr.send(null);
}

function initSurvey() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('resetsurvey') === '1') {
        document.cookie = 'settings=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        debugMode = true;
    }
    currentURL = getPathFromUrl(document.location.href);
    settings = getCookie('settings') || [];
    removeExpiredSettings();

    loadJsonFile('/get-survey/config', (settingconfig) => {
        logText(settingconfig);
        currentIP = settingconfig.currentip;
        adminStatus = settingconfig.status;
        if (adminStatus || !settingconfig.ipaddress.includes(currentIP)) {
            loadJsonFile('/surveyrules.json', (data) => {
                const values = Object.values(data);

                // Shuffle the values using the Fisher-Yates shuffle algorithm
                for (let i = values.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [values[i], values[j]] = [values[j], values[i]];
                }
                data = values;
                logText('loaded');

                const now = new Date(settingconfig.serverdate);
                data.forEach((element) => {
                    logText(element);
                    const postDate = new Date(element.postDate);
                    const expiryDate = new Date(element.expiryDate);
                    if (!ruleFound && (now > postDate) && (now < expiryDate)) {
                        const ruleURL = getPathFromUrl(element.url);
                        let requiresRandom = false;
                        if (ruleURL === currentURL) {
                            // eslint-disable-next-line max-len
                            const foundEntry = (settings && Array.isArray(settings) && settings.find((entry) => entry.url === currentURL && entry.ruleid === element.id)) || false;
                            if (foundEntry) {
                                logText('no no no');
                            } else {
                                logText(`Rule found${element.id}`);

                                if (element.random !== '1') {
                                    requiresRandom = true;
                                } else {
                                    ruleFound = true;
                                }
                                // eslint-disable-next-line max-len
                                const matched = (Math.floor(Math.random() * element.random) + 1) === 1;
                                if (matched) {
                                    ruleFound = true;
                                    logText('match start inactivity watch');
                                    let activeTimeout = element.displayIn;
                                    let timeout;
                                    let hidden = false;

                                    const resetTimer = function () {
                                        clearTimeout(timeout);
                                        timeout = setTimeout(() => {
                                            logText(`User has been inactive for over ${activeTimeout} seconds.`);

                                            document.removeEventListener('mousemove', resetTimer);
                                            document.removeEventListener('scroll', resetTimer);
                                            document.removeEventListener('click', resetTimer);
                                            document.removeEventListener('keydown', resetTimer);

                                            // Check if the tab is currently hidden
                                            if (document.hidden) {
                                                hidden = true;
                                            } else {
                                                const setEventCookie = function () {
                                                    logText('cookie set');
                                                    // eslint-disable-next-line max-len
                                                    // eslint-disable-next-line no-use-before-define,max-len
                                                    setSetting(currentURL, true, cookieExpiry, element.id);
                                                };

                                                // Remove event listeners
                                                let cookieExpiry;
                                                if (useSeconds) {
                                                    logText(element.oncePer);
                                                    // eslint-disable-next-line max-len
                                                    cookieExpiry = new Date(Date.now() + element.oncePer * 1000);
                                                } else {
                                                    // eslint-disable-next-line max-len
                                                    cookieExpiry = new Date(Date.now() + element.oncePer * 24 * 60 * 60 * 1000);
                                                }
                                                const tailImg = '/images/balloon-tail.svg';
                                                const tail = document.createElement('img');
                                                tail.src = tailImg;
                                                tail.classList.add('dialog-tail');

                                                const tailMobile = document.createElement('img');
                                                tailMobile.src = tailImg;
                                                tailMobile.classList.add('dialog-tail');

                                                const targetObj = document.querySelector('.headerNav__mainNav');
                                                const targetObjMobile = document.querySelector('.headerNav');
                                                const hoverObj = document.createElement('div');
                                                const hoverObjMobile = document.createElement('div');
                                                const hoverObjLine1 = document.createElement('p');
                                                const hoverObjLine2 = document.createElement('p');
                                                const hoverObjLine1Mobile = document.createElement('p');
                                                const hoverObjLine2Mobile = document.createElement('p');
                                                const hoverObjLink = document.createElement('a');
                                                const hoverObjLinkMobile = document.createElement('a');
                                                hoverObjLink.href = 'survey';
                                                hoverObjLink.text = element.teaserbuttontitle;
                                                hoverObjLinkMobile.href = 'survey';
                                                hoverObjLinkMobile.text = element.teaserbuttontitle;

                                                const handleSurveyClick = function (event) {
                                                    event.preventDefault();
                                                    const contentDiv = document.createElement('div');
                                                    const xhr = new XMLHttpRequest();
                                                    xhr.open('GET', `/get-survey?id=${element.surveyId.handle}`);
                                                    xhr.onload = function () {
                                                        if (xhr.status === 200) {
                                                            contentDiv.innerHTML = xhr.responseText;
                                                            openDialog(contentDiv);
                                                        } else {
                                                            console.error(xhr.statusText);
                                                        }
                                                    };
                                                    xhr.onerror = function () {
                                                        console.error('Network error');
                                                    };
                                                    xhr.send();

                                                    hoverObj.style.display = 'none';
                                                    hoverObjMobile.style.display = 'none';
                                                    setEventCookie();
                                                };

                                                hoverObjLink.addEventListener('click', handleSurveyClick);
                                                hoverObjLinkMobile.addEventListener('click', handleSurveyClick);
                                                hoverObjLine2.appendChild(hoverObjLink);
                                                hoverObjLine1.innerText = element.teasertext;
                                                hoverObjLine2Mobile.appendChild(hoverObjLinkMobile);
                                                hoverObjLine1Mobile.innerText = element.teasertext;

                                                hoverObj.appendChild(hoverObjLine1);
                                                hoverObj.appendChild(hoverObjLine2);
                                                hoverObj.appendChild(tail);
                                                hoverObjMobile.appendChild(hoverObjLine1Mobile);
                                                hoverObjMobile.appendChild(hoverObjLine2Mobile);
                                                hoverObjMobile.appendChild(tailMobile);

                                                hoverObj.classList.add('rounded-rectangle');
                                                hoverObj.classList.add('fade-in');
                                                hoverObjMobile.classList.add('rounded-rectangle');
                                                hoverObjMobile.classList.add('fade-in');

                                                const closeButton = document.createElement('div');
                                                const closeButtonMobile = document.createElement('div');
                                                closeButton.classList.add('close-button');
                                                closeButtonMobile.classList.add('close-button');
                                                const closeButtonClick = function (event) {
                                                    setEventCookie();
                                                    hoverObj.style.display = 'none';
                                                    hoverObjMobile.style.display = 'none';
                                                    targetObj.removeChild(hoverObj);
                                                    targetObjMobile.removeChild(hoverObjMobile);
                                                };
                                                closeButton.addEventListener('click', closeButtonClick);
                                                closeButtonMobile.addEventListener('click', closeButtonClick);

                                                hoverObj.appendChild(closeButton);
                                                hoverObjMobile.appendChild(closeButtonMobile);

                                                targetObj.appendChild(hoverObj);
                                                hoverObjMobile.classList.add('mobileonly');
                                                targetObjMobile.appendChild(hoverObjMobile);
                                            }
                                        }, activeTimeout * 1000); // 10000 ms = 10 seconds
                                    };

                                    // Listen for mouse move, scroll, click, and key events
                                    document.addEventListener('mousemove', resetTimer);
                                    document.addEventListener('scroll', resetTimer);
                                    document.addEventListener('click', resetTimer);
                                    document.addEventListener('keydown', resetTimer);
                                    resetTimer();

                                    // eslint-disable-next-line max-len
                                    // Listen for visibilitychange event to check if the tab is hidden
                                    document.addEventListener('visibilitychange', () => {
                                        if (!document.hidden && hidden) {
                                            // Reset the timer and set hidden to false
                                            activeTimeout = 2;
                                            resetTimer();
                                            hidden = false;
                                        }
                                    });
                                } else {
                                    logText('no match.');
                                }
                            }
                        }
                    }
                });
            });
        }
    });
}

const Survey = {

    init() {
        initSurvey();
    },
};

export default Survey;
