/* -------------------------------------------------------------------------- *
 * JavaScript Modules *
 * -------------------------------------------------------------------------- */

import Consent from './modules/consent';
import Notification from './modules/notification';
import StickyNav from './modules/stickyNav';
import Toggle from './modules/toggle';
import LazyLoad from './modules/lazyLoad';
import Carousel from './modules/carousel';
import Lightbox from './modules/lightbox';
import BackgroundImages from './modules/backgroundImages';
import Survey from './modules/survey';

/* -------------------------------------------------------------------------- *
 * Module Initialization *
 * -------------------------------------------------------------------------- */

/* ----------------- *
 * Load on All Pages *
 * ----------------- */

StickyNav.init();

/* ------------------ *
 * Load When Required *
 * ------------------ */

if (document.querySelector(Consent.selector) !== null) {
    Consent.init();
}

if (document.querySelector(Notification.selector) !== null) {
    Notification.init();
}

if (document.querySelector(Toggle.selector) !== null) {
    Toggle.init();
}

if (document.querySelector(LazyLoad.selector) !== null) {
    LazyLoad.init();
}

if (document.querySelector(Carousel.selector) !== null) {
    Carousel.init();
}

if (document.querySelector(Lightbox.selector) !== null) {
    Lightbox.init();
}

if (document.querySelector(BackgroundImages.selector) !== null) {
    BackgroundImages.init();
}
Survey.init();
