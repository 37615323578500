/* -------------------------------------------------------------------------- *
 * Lightbox Gallery *
 * -------------------------------------------------------------------------- *
 * Accessible lightbox gallery using the Topbii lightbox plugin.
 * https://kenwheeler.github.io/slick/
 */

/* global Tobii */

const settings = {
    selector: '.js-lightbox',
    tobii: {
        autoplayVideo: true,
        captions: false,
        counter: false,
        zoom: false,
    },
};

/* ------------- *
 * Module Export *
 * ------------- */

const Lightbox = {
    selector: settings.selector,

    init(selector = settings.selector, options = settings.tobii) {
        options.selector = selector;
        new Tobii(options);
    },
};

export default Lightbox;
